<template>
  <div class="fms-openlayers-capture">
    <div class="fms-openlayers-capture-map" :style="{ width: width+'px', height: height+'px' }">
      <flight-map :group-data="mapGroup"
                  :load-items="loadItem"
                  :interactive="false"
                  @map-rendercomplete="renderCompelete"
                  :overwriteSource="crossOriginSource"
                  ref="map"
      >
        <fly-route v-if="value.dataType === 'mission'"
                   ref="route"
                   :edit-mode="false"
                   :use-normal-icon="false"
                   :z-index="15"
                   :task="value.data.tasks"
                   :id="`${value.data.id}-capture`"
                   :name="value.data.name"
        ></fly-route>
      </flight-map>
    </div>
  </div>
</template>

<script>
import FlightMap from '@/components/OpenLayers/FlightMap.vue';
import FlyRoute from '@/components/OpenLayers/FlyRoute.vue';
import { mapActions, mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import { boundingExtent } from 'ol/extent';
import { transformExtent } from 'ol/proj';
import { GeographicApiToOl ,GeographicXyToOl } from '@/utils/map.js';
import config from 'config';

export default {
  name: 'CaptureThumbnailForOpenLayers',
  components: {
    FlightMap,
    FlyRoute,
  },
  props: {
    timeout: {
      type: Number,
      default: 30,
    },
    width: {
      default: 312,
    },
    height: {
      default: 210,
    },
    value: {
      type: Object,
      required: true,
    },
    promise: {
      type: Object,
      required: true,
    },
    loadItem: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      timeoutCheck: null,
      loading: null,
      loaded: false,
    };
  },
  computed: {
    mapGroup() {
      switch(this.value.dataType) {
        case 'group':
          return this.value.data;
        case 'mission':
          return this.group;
      }
    },
    crossOriginSource() {
      let sourceList = this.olSourceList;
      let anonymousSourceList = [];
      for (let source of sourceList) {
        if (source.crossOrigin === 'Anonymous') {
          anonymousSourceList.push(source);
        }
      }
      if (anonymousSourceList.length == 0) {
        console.warn('No source has property crossOrigin with value \'Anonymous\', create thumbnail with map layer require that.\nCapture without map layer');
      }
      return anonymousSourceList;
    },
    ...mapGetters({
      group: 'user/group',
      olSourceList: 'user/olSource',
    }),
  },
  methods: {
    renderCompelete(event) {
      if (this.loaded) {
        return;
      }
      this.loaded = true;
      // WORKAROUND: sometime rendercomplete fired, map not complete
      setTimeout(() => {
        // implement by just canvas
        // if (event.context && event.context.canvas) {
        //   clearTimeout(this.timeoutCheck);
        //   let canvas = event.context.canvas;
        //   this.promise.resolve(canvas.toDataURL());
        //   return;
        // }

        if (event.target && event.target.getViewport) {
          let viewport = event.target.getViewport();
          // viewport.querySelectorAll('.ol-rotate.ol-control').forEach((e) => { e.remove() })
          html2canvas(viewport).then((canvas) => {
            clearTimeout(this.timeoutCheck);
            this.promise.resolve(canvas.toDataURL());
            return;
          });
        }    
      }, 1500);
    },
    ...mapActions({
      mapReset: 'map/reset',
      centerChange: 'map/centerUpdate',
    }),
  },
  async mounted() {
    this.mapReset();
    let positions;
    let extentBox;
    switch (this.value.dataType) {
      case 'group':
        positions = this.value.data.fence.map(GeographicApiToOl);
        extentBox = boundingExtent(positions);
        this.$refs.map.fitBounds(extentBox);
        break;
      case 'mission':
        positions = this.value.data.tasks.map(GeographicXyToOl);
        extentBox = boundingExtent(positions);
        this.$refs.map.fitBounds(extentBox);
        break;
    }
    
    this.timeoutCheck = setTimeout(() => {
      this.promise.resolve(null);
      // this.promise.reject('capture image timeout');
    }, this.timeout * 1000);
  },
}
</script>

<style lang="scss">
  .fms-openlayers-capture {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .fms-openlayers-capture-map {
      position: relative;
    }
  }
</style>